import { SET_ALERT, REMOVE_ALERT, GET_NOTIFICATION, ERROR_NOTIFICATION } from './constants';
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios';

const version = 'v1.0'
export const setAlert = (msg, alertType) => dispatch => {
    const id = uuidv4();
    dispatch({
        type: SET_ALERT,
        payload: { msg, alertType, id }
    });
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
};

export const getNotification = () => async dispatch => {
    try {
        const res = await axios.get(`/api/${version}/notification`);
        dispatch({
            type: GET_NOTIFICATION,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: ERROR_NOTIFICATION,
            payload: { msg: err.response }
        });
    }
}