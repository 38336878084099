import {
    GET_SELECTED_DEVICE_RAW_DATA, ERROR_SELECTED_DEVICE_RAW_DATA, CLEAR_SELECTED_DEVICE_RAW_DATA,
    GET_BATTERY_ANALYSIS_GRAPH,ERROR_BATTERY_ANALYSIS_GRAPH
} from "./constants";

const initialState = {
    id: null,
    name: null,
    loading: true,
    error: '',
    rawData: [],
    count: 0,
    limit: 0,
    offset: 0,
    lineGraph: [],
    batteryData: [],
    batteryHealthStatus:'',
    isError:false
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SELECTED_DEVICE_RAW_DATA:
            return {
                ...state,
                id: payload.id,
                name: payload.name,
                rawData: payload.rawData,
                count: payload.count,
                limit: payload.limit,
                offset: payload.offset,
                loading: false,
                isError:false,
                error: ''
            }
        case GET_BATTERY_ANALYSIS_GRAPH:
            return {
                ...state,
                lineGraph: payload.lineGraphLookup,
                batteryData: payload.response,
                batteryHealthStatus:payload.batteryHealth,
                loading: false,
                error: '',
            }
        case ERROR_BATTERY_ANALYSIS_GRAPH:
        case ERROR_SELECTED_DEVICE_RAW_DATA:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
                batteryData:[],
                rawData: [],
                lineGraph:[],
                batteryHealthStatus:'',
                count: 0
            }
        case CLEAR_SELECTED_DEVICE_RAW_DATA:
            return initialState
        default:
            return state;
    }
}